import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	RecommendedApplicationsPreferences as RecommendedApplicationPreferencesType,
	RelevantWebsitesPreferences as RelevantWebsitePreferencesType,
	UsedApplicationPreferences as UsedApplicationPreferencesType,
	UsedApplicationPreference,
	UsedApplicationWithPreference,
	RelevantWebsiteWithPreference,
	RelevantWebsitePreference,
	UserPreferences
} from '../../api/user';
import { AppState } from "../../store";
import { pushEvent } from '../../Services/AnalyticsService';
import Dashboard from './Dashboard';
import { Spinner } from '@danfoss/webex-ui';
import DashboardItemPopup from './DashboardItemPopup';
import parse from 'html-react-parser';
import { DashBoardItem, OtherAppsProps } from './Types';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend'
import { isMobileDevice } from '../../utils';
import { DashboardItems } from '../../store/dashboadItems/types';
import { updateUserPreferences as updateUserPreferencesAction } from '../../store/dashboadItems/actions'

const setRecommendedDisplayPreferences = (item: DashBoardItem, recommendedApplicationsPreferences: RecommendedApplicationPreferencesType, functions: any) => {
	let hasPreference = false;

	let recommendedPreferences = recommendedApplicationsPreferences.preferences ? recommendedApplicationsPreferences.preferences.map(pref => {
		if (pref.client_id === item.client_id) {

			hasPreference = true;

			return {
				client_id: item.client_id,
				hidden: !item.hidden,
				display_order: (item.display_order || 0)
			}
		}

		return pref;
	}) : [];

	if (!hasPreference) {
		const itemPreference = {
			client_id: item.client_id || '',
			hidden: !item.hidden,
			display_order: (item.display_order || 0)
		}

		recommendedPreferences.push(itemPreference);

		functions.setRecommendedApplicationsPreferences({ preferences: recommendedPreferences });
	} else {

		functions.setRecommendedApplicationsPreferences({ preferences: recommendedPreferences });
	}
}

const setRelevantDisplayPreferences = (item: DashBoardItem, relevantWebsitesPreferences: RelevantWebsitePreferencesType, functions: any) => {
	let hasPreference = false;

	let relevantPreferences = relevantWebsitesPreferences.preferences ? relevantWebsitesPreferences.preferences.map(pref => {
		if (pref.id === item.id) {

			hasPreference = true;

			return {
				id: item.id,
				hidden: !item.hidden,
				display_order: (item.display_order || 0)
			}
		}

		return pref;
	}) : [];

	if (!hasPreference) {
		const itemPreference = {
			id: item.id || '',
			hidden: !item.hidden,
			display_order: (item.display_order || 0)
		}

		relevantPreferences.push(itemPreference);

		functions.setRelevantWebsitesPreferences({ preferences: relevantPreferences });
	} else {
		functions.setRelevantWebsitesPreferences({ preferences: relevantPreferences });
	}
}

const setUsedDisplayPreferences = (item: DashBoardItem, usedApplicationsPreferences: UsedApplicationPreferencesType, functions: any) => {
	let hasPreference = false;

	let preferences = usedApplicationsPreferences.preferences ? usedApplicationsPreferences.preferences.map(usedAppPref => {
		if (usedAppPref.client_id === item.client_id) {

			hasPreference = true;

			return {
				client_id: item.client_id,
				hidden: !item.hidden,
				display_order: (item.display_order || 0)
			}
		}

		return usedAppPref;
	}) : [];

	if (!hasPreference) {
		const itemPreference = {
			client_id: item.client_id || '',
			hidden: !item.hidden,
			display_order: (item.display_order || 0)
		}

		preferences.push(itemPreference);

		functions.setUsedApplicationsPreferences({ preferences: preferences });
	} else {
		functions.setUsedApplicationsPreferences({ preferences: preferences });
	}
}

const setDisplayPreferences = (item: DashBoardItem, itemType: string, usedApplicationsPreferences: UsedApplicationPreferencesType, recommendedApplicationsPreferences: RecommendedApplicationPreferencesType, relevantWebsitesPreferences: RelevantWebsitePreferencesType, functions: any) => {
	switch (itemType) {
		case "recommended":
			setRecommendedDisplayPreferences(item, recommendedApplicationsPreferences, functions);
			break;
		case "relevant":
			setRelevantDisplayPreferences(item, relevantWebsitesPreferences, functions);
			break;
		default:
			setUsedDisplayPreferences(item, usedApplicationsPreferences, functions);
			break;
	}
}

const setUsedMovePreferences = (item: DashBoardItem, usedApplicationsPreferences: UsedApplicationPreferencesType, functions: any) => {

	const usedApplicationToMove = usedApplicationsPreferences.preferences.find((pref: UsedApplicationPreference) => pref.client_id === item.client_id)

	const itemsToSort = [] as UsedApplicationPreference[];
	const itemsToSkip = [] as UsedApplicationPreference[];
	
	usedApplicationsPreferences.preferences.forEach(app => {
		if (isMobileDevice()) {
			if (app.dip_app_type === 'mobile_app' || app.dip_app_type === 'web') {
				itemsToSort.push(app);
			} else {
				itemsToSkip.push(app);
			}
		} else {
			if (app.dip_app_type !== 'mobile_app') {
				itemsToSort.push(app);
			} else {
				itemsToSkip.push(app);
			}
		}
	})

	// Add new display order based on moved item
	const usedApplicationsPreferencesSorted = itemsToSort.reduce((preferences: UsedApplicationPreference[], pref: UsedApplicationPreference) => {

		if (pref.display_order === (item.display_order || 0) + 1) {
			preferences.push({ ...pref, display_order: (usedApplicationToMove?.display_order || 0) });
		} else if (pref.client_id === usedApplicationToMove?.client_id) {
			preferences.push({ ...pref, display_order: (item.display_order || 0) + 1 });
		} else {
			preferences.push(pref);
		}

		return preferences;

	}, [])

	functions.setUsedApplicationsPreferences({ preferences: usedApplicationsPreferencesSorted })
}

const setRelevantMovePreferences = (item: DashBoardItem, dashboardItems: DashboardItems, relevantWebsitesPreferences: RelevantWebsitePreferencesType, functions: any) => {
	let position = 0;

	// Add missing dashboard items into preference collection
	dashboardItems.relevant_websites?.map((app: RelevantWebsiteWithPreference) => {

		const preference = relevantWebsitesPreferences.preferences.find((pref: RelevantWebsitePreference) => pref.id === app.id)

		if (preference === undefined) {
			relevantWebsitesPreferences.preferences.push({ id: (app.id || ''), hidden: app.hidden || false, display_order: position })
			functions.setRelevantWebsitesPreferences(relevantWebsitesPreferences)
		}

		position++;
	});

	const itemToMove = relevantWebsitesPreferences.preferences.find((pref: RelevantWebsitePreference) => pref.id === item.client_id)

	// Add new display order based on moved item
	const relevantWebsitesPreferencesSorted = relevantWebsitesPreferences.preferences.reduce((preferences: RelevantWebsitePreference[], pref: RelevantWebsitePreference) => {

		if (pref.display_order === item.display_order) {
			preferences.push({ ...pref, display_order: itemToMove?.display_order || 0 });
		} else if (pref.id === itemToMove?.id) {
			preferences.push({ ...pref, display_order: item.display_order });
		} else {
			preferences.push(pref);
		}

		return preferences;

	}, [])

	functions.setRelevantWebsitesPreferences({ preferences: relevantWebsitesPreferencesSorted })
}

const setMovePreferences = (item: DashBoardItem, itemType: string, dashboardItems: DashboardItems, usedApplicationsPreferences: UsedApplicationPreferencesType, relevantWebsitesPreferences: RelevantWebsitePreferencesType, functions: any) => {
	
	switch (itemType) {
		case "used":
			setUsedMovePreferences(item, usedApplicationsPreferences, functions)
			break;
		case "relevant":
			setRelevantMovePreferences(item, dashboardItems, relevantWebsitesPreferences, functions)
			break;
	}
}

const setDashBoardItemAfterReload = (dashboardItem: DashBoardItem, dashboardItems: DashboardItems, setDashboardItem: Function, setShowDashboardItemDescription: Function) => {
	if (dashboardItem.client_id) {

		let dashItem = dashboardItems.recommended_applications?.find(item => item.client_id === dashboardItem.client_id)

		if (!dashItem) {
			dashItem = dashboardItems.used_applications?.find(item => item.client_id === dashboardItem.client_id) || {} as UsedApplicationWithPreference;
		}


		if (dashItem?.translations?.description) {
			setDashboardItem(dashItem)
		} else {
			setDashboardItem({} as DashBoardItem)
			setShowDashboardItemDescription(false);
		}
	} else if (dashboardItem.id) {

		const dashItem = dashboardItems.relevant_websites?.find(item => item.id === dashboardItem.id)

		if (dashItem?.description) {
			setDashboardItem(dashItem)
		} else {
			setDashboardItem({} as DashBoardItem)
			setShowDashboardItemDescription(false);
		}
	}
}

const setSortOrder = (items: UsedApplicationWithPreference[], displayOrder: number) => {
	// Loop applications and add display_order on the ones not having a display_order
	const usedAppsWithDisplayOrder = items.map((app: UsedApplicationWithPreference) => {

		if (app.display_order === undefined) {
			displayOrder++;
			return { ...app, display_order: displayOrder }
		}

		return app
	});

	return usedAppsWithDisplayOrder;
}

const handleUseEffect = (dashboardItems:DashboardItems ):UsedApplicationWithPreference[] => {

	// Sort applications by name, to add sorting on the ones not having display_order set
	const usedAppsSortedByName = dashboardItems.used_applications?.filter((app: UsedApplicationWithPreference) => app.url)?.sort((item1, item2) => item1.name.localeCompare(item2.name)) || [];

	// Find the number og items having a display_order
	let displayOrder = (usedAppsSortedByName?.filter(usedApp => usedApp.display_order !== undefined) || []).length;

	const itemsToSort = [] as UsedApplicationWithPreference[];
	const itemsToSkip = [] as UsedApplicationWithPreference[];

	usedAppsSortedByName.forEach(app => {
		if (isMobileDevice()) {
			if (app.dip_app_type === 'mobile_app') {
				itemsToSort.push(app);
			} else {
				itemsToSkip.push(app);
			}
		} else {
			if (app.dip_app_type !== 'mobile_app') {
				itemsToSort.push(app);
			} else {
				itemsToSkip.push(app);
			}
		}
	})

	if (isMobileDevice()) {
		itemsToSort.sort((a, b) => a.dip_app_type.localeCompare(b.dip_app_type));
	}

	return setSortOrder(itemsToSort.concat(itemsToSkip), displayOrder);
}

const DashboardWrapper: React.FC<OtherAppsProps> = ({ profile, dashboardItems, updateUserPreferences, setLeaveConfirmValues }) => {

	const [usedApplicationsPreferences, setUsedApplicationsPreferences] = useState({ preferences: [] } as UsedApplicationPreferencesType);
	const [relevantWebsitesPreferences, setRelevantWebsitesPreferences] = useState({ preferences: [] } as RelevantWebsitePreferencesType);
	const [recommendedApplicationsPreferences, setRecommendedApplicationsPreferences] = useState({} as RecommendedApplicationPreferencesType);
	const [showDashboardItemDescription, setShowDashboardItemDescription] = useState(false);
	const [dashboardItem, setDashboardItem] = useState({} as DashBoardItem);
	const [editMode, setEditMode] = React.useState(false);
	const backendFactory = isMobileDevice() ? TouchBackend : HTML5Backend;

	useEffect(() => {
		setRelevantWebsitesPreferences({ preferences: dashboardItems.relevant_websites_preferences || [] })
		setRecommendedApplicationsPreferences({ preferences: dashboardItems.recommended_applications_preferences || [] })

		const usedAppsWithDisplayOrder = handleUseEffect(dashboardItems);

		setDashBoardItemAfterReload(dashboardItem, dashboardItems, setDashboardItem, setShowDashboardItemDescription);

		setUsedApplicationsPreferences({ preferences: usedAppsWithDisplayOrder?.map(app => { return ({ client_id: app.client_id, hidden: app.hidden, display_order: app.display_order, dip_app_type: app.dip_app_type }) }) || [] })

	}, [dashboardItems, dashboardItems.relevant_websites_loaded, dashboardItems.used_applications_loaded, dashboardItems.recommended_applications_loaded]);

	useEffect(() => {
		// Push an event that a user entered the applications page
		pushEvent(
			{
				'event': 'virtualPageview',
				'page': `${(window as any).location.hostname}/other-apps`
			}
		);
	}, []);



	const handleInformationIconClick = (item: DashBoardItem) => {
		setDashboardItem(item)
		setShowDashboardItemDescription(!showDashboardItemDescription);
	}

	const toggleApplicationDescription = () => {
		setShowDashboardItemDescription(!showDashboardItemDescription);
	}

	const setLeaveValues = (obj1: any, obj2: any, resetFunction: Function) => {
		setLeaveConfirmValues && setLeaveConfirmValues(obj1, obj2, true, resetFunction, document.getElementById('saveButton'))
	}

	const handleDashboardItemDisplay = (item: DashBoardItem, itemType: string) => {

		// we need to tell the clickoutsidewrapper that a change have happened. No need to send 
		// actual values of the changed events, just send two string that doesn't match 
		setLeaveValues("1", "2", () => {handleResetUserPreferences(); setEditMode(false)});

		setDisplayPreferences(item, itemType, usedApplicationsPreferences, recommendedApplicationsPreferences, relevantWebsitesPreferences, { setRecommendedApplicationsPreferences, setRelevantWebsitesPreferences, setUsedApplicationsPreferences });
	}

	const handleMoveDashboardItem = (item: any, itemType: string) => {

		// we need to tell the clickoutsidewrapper that a change have happened. No need to send 
		// actual values of the changed events, just send two string that doesn't match 
		setLeaveValues("1", "2", () => {handleResetUserPreferences(); setEditMode(false)});
		setMovePreferences(item, itemType, dashboardItems, usedApplicationsPreferences, relevantWebsitesPreferences, { setRecommendedApplicationsPreferences, setRelevantWebsitesPreferences, setUsedApplicationsPreferences });
	}

	const handleUpdateUserPreferences = () => {
		updateUserPreferences({
			isMobile: isMobileDevice(),
			used_application_preferences: usedApplicationsPreferences,
			recommended_application_preferences: recommendedApplicationsPreferences,
			relevant_websites_preferences: relevantWebsitesPreferences
		});

		// we need to tell the clickoutsidewrapper that a change have happened. No need to send 
		// actual values of the changed events, just send two string that doesn't match 
		setLeaveValues("1", "1", () => {});
	}

	const handleResetUserPreferences = () => {
		setRecommendedApplicationsPreferences({ preferences: [] });
		setUsedApplicationsPreferences({ preferences: [] });
		setRelevantWebsitesPreferences({ preferences: [] })

		updateUserPreferences({
			isMobile: isMobileDevice(),
			used_application_preferences: { preferences: [] },
			recommended_application_preferences: { preferences: [] },
			relevant_websites_preferences: { preferences: [] }
		});

		// we need to tell the clickoutsidewrapper that a change have happened. No need to send 
		// actual values of the changed events, just send two string that doesn't match 
		setLeaveValues("1", "1", () => {});

	}

	if (showDashboardItemDescription) {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	return !dashboardItems ? (<Spinner visible={!dashboardItems} />) : (
		<>
			{showDashboardItemDescription && (
				<DashboardItemPopup
					headerText={dashboardItem.translations?.name || dashboardItem.name}
					bodyText={parse(dashboardItem.translations?.description || dashboardItem.description || '')}
					togglePopup={toggleApplicationDescription}
				/>
			)}
			{ /* @ts-ignore */}
			<DndProvider backend={backendFactory}>
				<Dashboard
					profile={profile}
					dashboardItems={{
						...dashboardItems,
						used_applications_preferences: usedApplicationsPreferences.preferences,
						recommended_applications_preferences: recommendedApplicationsPreferences.preferences,
						relevant_websites_preferences: relevantWebsitesPreferences.preferences
					}}
					handleInformationIconClick={handleInformationIconClick}
					handleMoveDashboardItem={handleMoveDashboardItem}
					handleDashboardItemDisplay={handleDashboardItemDisplay}
					handleUpdateUserPreferences={handleUpdateUserPreferences}
					handleResetUserPreferences={handleResetUserPreferences}
					editMode={editMode}
					setEditMode={setEditMode}
				/>
			</DndProvider>
		</>
	);
};

const mapDispatchToProps = (dispatch: any) => () => ({
	updateUserPreferences: (userPreferences: UserPreferences) => dispatch(updateUserPreferencesAction(userPreferences))
});

const mapStateToProps = (state: AppState) => ({
	profile: state.profile,
	cultures: state.cultures,
	dashboardItems: state.dashboardItems
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardWrapper);
