import { Culture } from '../../store/cultures/types';
import { Profile } from '../../store/profile/types';
import queryString from 'query-string';
import DOMPurify from 'dompurify';
import { getCookieValueByName } from '../cookies';

const getValidCulture = (cultures: Culture[], culture: string | undefined | null) => {
    const validCulture = culture && cultures && cultures.find((c: Culture) => c.id === culture && c.has_been_translated);

    return validCulture && validCulture.id;
}

export const getUiLocales = () : string => {
    const {search, hash} = window.location;
    const queryStringElements = queryString.parse(search);
    const hashQueryStringElements = (hash && queryString.parse(hash.substr(hash.indexOf('?')))) || {} ;

    return DOMPurify.sanitize(queryStringElements.ui_locales as string) || DOMPurify.sanitize(hashQueryStringElements.ui_locales as string) || '';
}

export const getUserLanguage = (profile: Profile | undefined, cultures: Culture[]) : string => {

    // Check if a culture is saved in a cookie. If then use that culture
    const cookieCulture = getCookieValueByName("forward_ui_locales")

    if (cookieCulture) {
        return cookieCulture;
    }

    const uiLocalesCulture = getValidCulture(cultures, getUiLocales());
    const browserLan = getValidCulture(cultures, navigator.language) || getValidCulture(cultures, navigator.language.split('-')[0]);
    const userSelectedCulture = getValidCulture(cultures, sessionStorage.getItem('selectedCulture'));
    const profileCulture = getValidCulture(cultures, profile && profile.language && profile.language.id);
    return (uiLocalesCulture || userSelectedCulture || profileCulture || browserLan || 'en');
}

