/* eslint-disable react/jsx-no-bind,jsx-a11y/label-has-for,class-methods-use-this,prefer-rest-params */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps, reset, hasSubmitSucceeded } from 'redux-form';
import validate from './validation';
import { Spinner } from '@danfoss/webex-ui';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import { AppState } from "../../store";
import { Profile, Identity } from '../../store/profile/types';
import { urlJoin } from 'url-join-ts';
import Grid from '@material-ui/core/Grid';
import ProfileFields from './ProfileFields';
import Typography from '@material-ui/core/Typography';
import { getDepartments, getLanguages, getAreasOfInterest, getCompanyTypes, Department, Language, AreaOfInterest, CompanyType } from '../../api/references';
import { getCountries, getStates, getCallingCodes, CallingCode, Country, State, getStatesFormatted, LocationApiCountries, LocationApiCallingCodes, LocationApiCountryValue, getTimezonesFormatted, getTimezones, Timezone } from '../../api/locationApi';
import settings from '../../config/settings';
import parse from 'html-react-parser';
import FormEditConfirm from './FormEditConfirm';
import equal from 'deep-equal';
import { useStyles } from './Styles';
import { useTranslation } from 'react-i18next';
import { EditProfileFormProps } from './Types';
import { getUserIdentity } from '../../store/profile/helpers';
import { FieldsMetaData, FieldMetaData } from '../../store/fieldsMetadata/types';
import { Application, getApplication } from '../../api/application';
import queryString from 'query-string';
import DOMPurify from 'dompurify';
import { useParams } from 'react-router-dom';

function asyncValidate(values: any, dispatch: any, props: any) {
	return new Promise((resolve, reject) => {
		const errors = validate(values, props);

		if (Object.keys(errors).length === 0) {
			resolve(true);
		}
		reject(errors);
	});
}

const wasDataUpdated = (profileRef: React.MutableRefObject<Profile>, propsProfile: Profile | undefined) => {
	if (!equal(prepareForCompare(profileRef.current), prepareForCompare(propsProfile || {}))) {
		return true;
	}

	return false;
}

const prepareForCompare = (profileData: Profile) => {

	// We need to remove empty values since they are temporary for new users only
	const cleanedProfile = Object.keys(profileData).reduce((result, key) => {
		// @ts-ignore
		if (typeof (profileData[key]) === 'string' && profileData[key] !== '') {
			// @ts-ignore
			(result as any)[key] = profileData[key];
		}
		return result;
	}, {})

	// We need to remove translations from dropdown values otherwise
	// we will see a change if you select a value and undo that change.
	return {
		...cleanedProfile, area_of_interest: { ...profileData.area_of_interest, text: '' },
		areas_of_interest: { ...profileData.areas_of_interest, text: '' },
		department: { ...profileData.department, text: '' },
		company_type: { ...profileData.company_type, text: '' },
		country: { ...profileData.country, text: '' },
		language: { ...profileData.language, text: '' },
		state: { ...profileData.state, text: '' },
		timezone: { ...profileData.timezone, text: '' },
		phone_calling_code: { ...profileData.phone_calling_code, text: '' }
	}
}

const isPhoneValid = (Phone: string | undefined, fieldsMetadata: FieldsMetaData) => {
	const phoneMetadata: (FieldMetaData | undefined) = (fieldsMetadata?.fields || []).find((field: FieldMetaData) => field.name === 'phone_number')

	const reg = new RegExp(phoneMetadata?.validation?.regex || "^[0-9\\(\\)\\- ]*[0-9]+$");
	if (Phone && !reg.test(`${Phone}`)) {
		return true;
	}

	return false;
}

const validateForm = (profileRef: React.MutableRefObject<Profile>, fieldsMetadata: FieldsMetaData) => {
	
	if (!profileRef) return;

	let fieldsWithErrors = [];

	fieldsMetadata.fields.forEach((fmd: FieldMetaData) => {
		// @ts-ignore
		if (fmd.required && ((typeof profileRef.current[fmd.name] === 'object' && !profileRef.current[fmd.name].id) || !profileRef.current[fmd.name])) {
			fieldsWithErrors.push(fmd.name);
		}
	});

	if (fieldsWithErrors.length > 0) {
		return true;
	}

	const {phone_number_cleaned, phone_calling_code, country, state } = profileRef.current;

	if ((phone_number_cleaned || "") !== "" && (phone_calling_code?.id === "" || !phone_calling_code?.id)) {
		return true;
	}

	if (!!phone_calling_code?.id && (phone_number_cleaned === "" || phone_number_cleaned === undefined)) {
		return true;
	}

	const embargoCountries = settings.embargoRestrictions.countries.split(',');
	const embargoRegions = settings.embargoRestrictions.regions.split(',');

	if (embargoCountries.includes(country?.id || "")) {
		return true
	}

	if (embargoRegions.includes(state?.id || "")) {
		return true
	}

	if (isPhoneValid(phone_number_cleaned, fieldsMetadata)) {
		return true;
	}

	return false;
}

const handleUseEffect = (profiles: any, cultures: any, submitSucceeded: boolean, functions: any) => {
	if (!profiles.propsProfile || !profiles.profileRef.current) return;

	if (submitSucceeded) {
		functions.setLoading(false);
	}

	if (profiles.profileRef.current !== profiles.propsProfile) {
		functions.setProfileStateAndRef(profiles.propsProfile);
	}

	if ((profiles.profileRef.current.country && (profiles.profileRef.current.country.id || '')) !== (profiles.propsProfile.country && (profiles.propsProfile.country.id || ''))) {

		const getCountryStatesAsync = async () => {
			const countryStates = await functions.getCountryStates((profiles.profileRef.current.country && profiles.profileRef.current.country.id) || '');
			functions.setStates(getStatesFormatted(countryStates));
		}

		getCountryStatesAsync();
	}

	// Update state data if language has changed
	if ((cultures.prevCurrentCulture || cultures.currentCulture) !== cultures.propsCultures.currentCulture) {
		functions.updateStateData(cultures.propsCultures.currentCulture);
		functions.setPrevCurrentCulture(cultures.propsCultures.currentCulture);
	}


}

const getCountryId = (profileRef: React.MutableRefObject<Profile>) => {
	return ((profileRef.current && profileRef.current.country_id) || (profileRef.current.country && profileRef.current.country.id)) || '';
}

const isFederatedUser = (userIdentity: Identity | undefined) => {
	return userIdentity?.metadata?.IsFederation || false
}

const getSortedFormattetCallingCodes = (locationApiCallingCodes: LocationApiCallingCodes) => {

	let CallingCodesFormatted: CallingCode[] = [];

	locationApiCallingCodes?.value.forEach((cc: any) => {
		cc.calling_codes.forEach((callingCodeValue: any) => {
			if (callingCodeValue.indexOf(";") > -1) {
				const callingCodeValues = callingCodeValue.split(";");
				callingCodeValues.forEach((ccValue: any) => {
					CallingCodesFormatted.push({ id: `${cc.alpha2}+${ccValue}`, text: `${cc.name} (+${ccValue})` });
				})
			} else {
				CallingCodesFormatted.push({ id: `${cc.alpha2}+${callingCodeValue}`, text: `${cc.name} (+${callingCodeValue})` });
			}
		})
	});

	return [...CallingCodesFormatted].sort((a, b) => a.text.localeCompare(b.text));
}

const isNameToBeChanged = (field: string, value: any, newProfile: any, profileRef: React.MutableRefObject<Profile>, setProfileStateAndRef: Function, setIsFormDirtyStataAndRef: Function) => {
	let name;
	if (field === 'first_name') {
		name = `${value} ${profileRef.current.last_name}`;
	} else if (field === 'last_name') {
		name = `${profileRef.current.first_name} ${value}`;
	}

	if (name) {
		setProfileStateAndRef({ ...newProfile, name: name });
		setIsFormDirtyStataAndRef(true);
	}
}

const scrollToTop = (showConfirmation: boolean, showLeaveConfirm: boolean) => {
	if (showConfirmation || showLeaveConfirm) {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}
}

const isLoading = (loading: boolean, propsProfile: Profile | undefined) => {
	return loading || !propsProfile?.identities;
}

const getCountriesFormatted = (locationApiCountries: LocationApiCountries) => {
	return locationApiCountries?.value.map((country: LocationApiCountryValue) => {
		return { id: country.alpha2, text: country.name };
	});
}	

const goToApp = (redirectUrl: string) => {
	document.location.href = redirectUrl;
}

const ShowEditConfirmPopup = (applicationName: string, redirectUrl: string, showConfirmation: boolean, showLeave: boolean, functions: any) => {
	return showConfirmation && (
		<FormEditConfirm
			showLeave={showLeave}
			setShowConfirmation={() => functions.setShowConfirmation(false)}
			onLeave={(event: any) => {functions.setShowConfirmation(false); functions.fireEvent(event)}}
			onGoToApplication={() => goToApp(redirectUrl)}
			applicationName={applicationName}
		/>
	)
}

const showValidationErrors = (validationErrors: boolean, classes: any, t: Function) => {
	return validationErrors && (
		<Grid container direction="row" className={classes.validationErrorContainer}>
			<span className={classes.validationErrorIcon}>
				<img src={urlJoin(settings.staticFiles.endpoint, '/images/system/ValidationWarning.svg')} className={classes.icon} alt="" />
			</span>
			<span className={classes.validationErrorText}>
				{parse(t('user_profile.validationErrors'))}
			</span>
		</Grid>
	)
}

const disbaleSpinnerButton = (validationErrors: boolean, profileRef: React.MutableRefObject<Profile>, propsProfile: Profile | undefined) => {
	return (validationErrors || !wasDataUpdated(profileRef, propsProfile));
}

const getClassName = (validationErrors: boolean, classes: any) => {
 return validationErrors ? classes.buttonContainer : classes.buttonContainerSpace;
}

const EditProfileForm: React.FC<EditProfileFormProps & InjectedFormProps<{}>> = ({ onSubmit, change, submitSucceeded, resetForm, accessToken, propsProfile, propsCultures, fieldsMetadata, nativeMode, currentCulture, setLeaveConfirmValues, setValidationErrors, fireEvent }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [countries, setCountries] = useState([] as Country[]);
	const [states, setStates] = useState([] as State[]);
	const [departments, setDepartments] = useState([] as Department[]);
	const [areasOfInterest, setAreasOfInterest] = useState([] as AreaOfInterest[]);
	const [companyTypes, setCompanyTypes] = useState([] as CompanyType[]);
	const [languages, setLanguages] = useState([] as Language[]);
	const [callingCodes, setCallingCodes] = useState([] as CallingCode[]);
	const [timezones, setTimezones] = useState([] as Timezone[]);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [showLeaveConfirm, setShowLeaveConfirm] = useState(false);
	const [eventSaved, setEventSaved] = useState(false);
	const [isFormDirty, setIsFormDirty] = useState(false);
	const [showLeave, setShowLeave] = useState(false);
	const [pageLanguage, setPageLanguage] = useState('');
	const [clickEvent, setClickEvent] = useState({} as MouseEvent);
	const [loading, setLoading] = useState(true);
	const [profile, setProfile] = useState({} as Profile)
	const [prevCurrentCulture, setPrevCurrentCulture] = useState('');
	const [isFederation, setIsFederation] = useState(false);
	const [application, setApplication] = useState({} as Application)
	const [redirectUrl, setRedirectUrl] = useState('')

	const isFormDirtyRef = React.useRef(isFormDirty);
	const profileRef = React.useRef(profile);
	const eventRef = React.useRef(clickEvent);
	const { refreshKey } = useParams();
	
	const setIsFormDirtyStataAndRef = (isDirty: boolean) => {
		isFormDirtyRef.current = isDirty;
		setIsFormDirty(isDirty);
	};

	const setProfileStateAndRef = (profileData: Profile) => {
		profileRef.current = profileData;
		setProfile(profileData);
	};

	useEffect(() => {

		setProfileStateAndRef({ ...propsProfile });

		//Get querystring parameters
		const parsed = queryString.parse(window.location.search);
		
		// Set initial state data
		const getDataAsync = async () => {
			setPrevCurrentCulture(currentCulture);
			await updateStateData(currentCulture);

			// Get application based on clientId in URL if it exist
			if (parsed.client_id && parsed.redirect_url) {
				// take clientId if part of querystring
				const clientId = DOMPurify.sanitize(parsed.client_id as string);
				
				const app = await getApplication(clientId, propsCultures.currentCulture)

				setApplication(app);
				setRedirectUrl(parsed.redirect_url as string)
			}

		}

		getDataAsync();
		
		const userIdentity = getUserIdentity(propsProfile);
		setIsFederation(isFederatedUser(userIdentity))

	}, [refreshKey]);

	useEffect(() => {

		handleUseEffect({propsProfile: propsProfile, profileRef: profileRef}, {prevCurrentCulture: prevCurrentCulture, currentCulture:currentCulture, propsCultures:propsCultures}, submitSucceeded, { 'setLoading': setLoading, 'setProfileStateAndRef': setProfileStateAndRef, 'getCountryStates': getCountryStates, 'setStates': setStates, 'updateStateData': updateStateData, 'setPrevCurrentCulture': setPrevCurrentCulture });

	}, [propsProfile, propsCultures, submitSucceeded]);

	const updateStateData = async (newLanguage: string) => {
		const countryId = getCountryId(profileRef);

		let [locationApiDepartments, locationApiAreasOfInterest, locationApiCompanyTypes, locationApiLanguages, locationApiCallingCodes, locationApiTimezones, locationApiCountries, countryStates] = await Promise.all([
			getDepartments(newLanguage),
			getAreasOfInterest(newLanguage),
			getCompanyTypes(newLanguage),
			getLanguages(),
			getCallingCodes(accessToken, newLanguage),
			getTimezones(accessToken),
			getCountries(accessToken, newLanguage),
			getStates(accessToken, countryId, newLanguage)
		]);

		const CountriesFormatted = getCountriesFormatted(locationApiCountries);
		
		const statesFormatted = getStatesFormatted(countryStates);
		const timezonesFormatted = getTimezonesFormatted(locationApiTimezones);

		setCountries(CountriesFormatted);
		setTimezones(timezonesFormatted);
		setStates(statesFormatted);
		setDepartments(locationApiDepartments.items);
		setCompanyTypes(locationApiCompanyTypes.items);
		setAreasOfInterest(locationApiAreasOfInterest.items);
		setLanguages(locationApiLanguages.items);
		setCallingCodes(getSortedFormattetCallingCodes(locationApiCallingCodes))
		setLoading(false);
		setPageLanguage(newLanguage);
	}

	const getCountryStates = (countryId: string) => {
		return getStates(accessToken, countryId, pageLanguage);
	}

	const handleCountryChange = async (target: any) => {

		if (target.value !== '') {

			const countryStates = await getCountryStates(target.value);
			const StatesFormatted = getStatesFormatted(countryStates)
			setStates(StatesFormatted);

		} else {

			setStates([] as State[]);

		}

		change("state", '');
		handleChange({ country: { id: target.value, text: "" }, state: { id: "", text: "" } });

		sendLeaveConfirmValues()
	}

	const sendLeaveConfirmValues = () => {

		setValidationErrors && setValidationErrors((validateForm(profileRef, fieldsMetadata || {} as FieldsMetaData)) ? t('user_profile.validationErrors') : "");

		// we need to tell the clickoutsidewrapper that a change have happened. No need to send 
		// actual values of the changed events, just send two string that doesn't match 
		setLeaveConfirmValues && setLeaveConfirmValues("1", "2", true, resetForm, document.getElementById('saveButton'), setShowLeave)
	}

	const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
		const refProfile = profileRef.current;
		e.preventDefault();
		setLoading(true);
		setShowLeaveConfirm(false);
		
		const stateId = refProfile?.state?.id.trim() ?? "";
		const countryId = refProfile?.country?.id.trim() ?? "";
		const timezoneId = refProfile?.timezone?.id.trim() ?? "";
		const departmentId = refProfile?.department?.id.trim() ?? "";
		const companyTypeId = refProfile?.company_type?.id.trim() ?? "";
		const areaOfInterestIds = refProfile?.areas_of_interest ?? [];
		const languageId = refProfile?.language?.id.trim() ?? "";

		let dataToSend = {
			state_id: stateId,
			country_id: countryId,
			timezone_id: timezoneId,
			department_id: departmentId,
			company_type_id: companyTypeId,
			areas_of_interest_ids: areaOfInterestIds.map((area: AreaOfInterest) => area.id),
			language_id: languageId,
			city: refProfile.city,
			zip: refProfile.zip,
			street: refProfile.street,
			calling_code_id: refProfile.phone_calling_code?.id || '',
			phone_number: refProfile.phone_number_cleaned || '',
			company: refProfile.company
		}

		// We can not send first_name and last_name if Danfoss or Salesforce users. If DB connection
		// then it is ok to send these attributes to the user update endpoint
		if (!isFederation) {
			Object.assign(dataToSend, { first_name: refProfile.first_name, last_name: refProfile.last_name })
		}

		onSubmit(dataToSend);

		setShowConfirmation(!nativeMode);
		setIsFormDirtyStataAndRef(false);
		setEventSaved(false);

		setLeaveConfirmValues && setLeaveConfirmValues("1", "1", true, resetForm, document.getElementById('saveButton'))
	};

	const handleFieldChange = (field: string, value: any) => {
		const newProfile = { ...profileRef.current, [field]: value };

		setProfileStateAndRef(newProfile)
		setIsFormDirtyStataAndRef(true);

		isNameToBeChanged(field, value, newProfile, profileRef, setProfileStateAndRef, setIsFormDirtyStataAndRef)

		sendLeaveConfirmValues();
		
	};

	const handleChange = (value: any) => {
		setProfileStateAndRef({ ...profileRef.current, ...value });
		setIsFormDirtyStataAndRef(true);
		sendLeaveConfirmValues();
	};

	const handleMultiSelectChange = (target: any) => {
		const valueArray = target.value.map((area:string) => ({ id: area, text: "" }));
		setProfileStateAndRef({ ...profileRef.current, [target.name]: valueArray });
		setIsFormDirtyStataAndRef(true);
		sendLeaveConfirmValues();
	};

	const handleSelectChange = (target: any) => {
		setProfileStateAndRef({ ...profileRef.current, [target.name]: {id: target.value}});
		setIsFormDirtyStataAndRef(true);
		sendLeaveConfirmValues();
	};

	if (isLoading(loading, propsProfile) || !fieldsMetadata) {
		return (<Spinner visible={isLoading(loading, propsProfile)} />)
	}
	
	scrollToTop(showConfirmation, showLeaveConfirm);
	
	const validationErrors = validateForm(profileRef, fieldsMetadata) || false;
	
	return (
		<>
			<Grid container className={classes.formEditProfileroot} id="ClickoutsideContainer">
				<Typography className={classes.header}>
					{t('user_profile.header')}
				</Typography>
				<Grid container alignItems="flex-start" direction="row">
					<Typography className={classes.descriptionText}>
						{parse(t('user_profile.description'))}
					</Typography>
				</Grid>
				<div id="fieldsContainer" className={classes.fieldContainer}>
					<form onSubmit={submitForm} id="profile">
						<Spinner visible={!profileRef.current.identities} />
						<ProfileFields handleSelectChange={handleSelectChange} handleMultiSelectChange={handleMultiSelectChange} handleCountryChange={(country: Country) => handleCountryChange(country)} profile={profileRef.current} attributeValues={{ countries, states, departments, companyTypes, areasOfInterest, languages, callingCodes, pageLanguage, accessToken, timezones }} handleFieldChange={handleFieldChange} fieldsMetadata={fieldsMetadata} handleChange={handleChange} />
						<Grid container alignItems="flex-start" justifyContent="flex-start" direction="row" className={getClassName(validationErrors, classes)}>
							<SpinnerButton
								type="submit"
								variant="contained"
								color="primary"
								className={classes.button}
								disabled={disbaleSpinnerButton(validationErrors, profileRef, propsProfile)}
								pathToImagesFolder={urlJoin(settings.staticFiles.endpoint, '/images')}
								spinnerVisible={loading}
								id="saveButton"
							>
								{t('button.save_changes')}
							</SpinnerButton>
						</Grid>
						{ showValidationErrors(validationErrors, classes, t) }
						
					</form>
					{
						ShowEditConfirmPopup((application.translations?.name || application.name || '') || '', redirectUrl, showConfirmation, showLeave, {'fireEvent': fireEvent, 'setShowConfirmation': setShowConfirmation })
					}
				</div>
			</Grid>

		</>
	);
}

const mapStateToProps = (state: AppState) => ({
	propsProfile: state.profile,
	propsCultures: state.cultures,
	fieldsMetadata: state.fieldsMetadata,
	submitSucceeded: hasSubmitSucceeded('dipProfile')(state)
});

const mapDispatchToProps = (dispatch: any) => () => ({
	resetForm: () => dispatch(reset('dipProfile'))
});

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(EditProfileForm)

const profileForm = reduxForm({
	form: 'dipProfile',
	validate,
	asyncValidate,
	asyncBlurFields: [],
	asyncChangeFields: ['first_name', 'last_name', 'language', 'country', 'phone_number_cleaned']	
})(connectedComponent);


export default connect((state: AppState) => ({
	initialValues: {
		phone_number_cleaned: state.profile ? state.profile.phone_number_cleaned : '',
		phone_calling_code: state.profile?.phone_calling_code?.id || '',
		country: state.profile?.country?.id || '',
		language: state.profile?.language?.id || '',
		first_name: state.profile?.first_name || '',
		last_name: state.profile?.last_name || '',
		fieldsMetadata: state.fieldsMetadata		
	},
}))(profileForm);
