import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Application, MobileAppInfo } from '../../api/application';
import {
	UsedApplicationWithPreference,
	RelevantWebsiteWithPreference,
	RecommendedApplicationWithPreference,
	UsedApplicationPreference,
	RecommendedApplicationPreference,
	RelevantWebsitePreference
} from '../../api/user';
import ModalPopup from '../../components/Modal/ModalPopup';
import parse from 'html-react-parser';
import RedirectToNativeApp from '../Routes/RedirectToMobileApp';
import { useStyles } from './Styles';
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';
import { Spinner } from '@danfoss/webex-ui';
import DashboardItemPopup from './DashboardItemPopup';
import { DashboardProps } from './Types';
import DashBoardItems from './DashBoardItems';
import Button from '@material-ui/core/Button';
import { Profile } from '../../store/profile/types';
import { DashboardItems } from '../../store/dashboadItems/types';
import { isMobileDevice } from '../../utils';
import { isIOS } from 'mobile-device-detect';

const getDashboardItemIcon = (application: Application) => {

	if (application && application.dip_app_type === 'client_app') {
		return '/images/system/AppDashboard_Desktop.svg';
	} else if (application && application.dip_app_type === 'web') {
		return '/images/system/AppDashboard_Web.svg';
	} else if (application && application.dip_app_type === 'mobile_app') {
		return '/images/system/AppDashboard_Mobile.svg';
	}

	return '';
}

const getDeepLink = (application: RecommendedApplicationWithPreference) => {
	if (isIOS) {
		return (application.meta_data?.mobile_app_info.find(info => info.os === 'ios') || {} as MobileAppInfo).deep_link;
	} else {
		return (application.meta_data?.mobile_app_info.find(info => info.os === 'android') || {} as MobileAppInfo).deep_link;
	}
}

const handleBannerClick = (application: RecommendedApplicationWithPreference, isActive: boolean, toggleInactiveAppPopup: Function, toggleClientAppPopup: Function, toggleMobileAppPopup: Function) => {

	if (!isActive) {
		toggleInactiveAppPopup();
		return;
	}

	if (!application) return;

	// If web application
	if (application.dip_app_type === 'web') {
		(window as any).open(application.url)
	}

	// If desktop application
	if (application.dip_app_type === 'client_app') {
		toggleClientAppPopup();
	}

	//If mobile application
	if (application.dip_app_type === 'mobile_app') {
		if (application.meta_data?.mobile_app_info) {

			let deepLink = getDeepLink(application);

			if (deepLink?.startsWith("https")) {
				document.location.href = deepLink;
			} else {
				toggleMobileAppPopup(application)
			}

		}
	}
}

const getFilteredRecommendedApps = (dashboardItems: DashboardItems) => {

	return (dashboardItems.recommended_applications || []).reduce((apps: RecommendedApplicationWithPreference[], app: RecommendedApplicationWithPreference) => {

		const preference = dashboardItems?.recommended_applications_preferences?.find((pref: RecommendedApplicationPreference) => pref.client_id === app.client_id)

		if (!(dashboardItems.used_applications || []).find(usedApp => usedApp.client_id === app.client_id)) {
			apps.push({ ...app, hidden: preference?.hidden || false });
		}

		return apps;

	}, []);
}

const getReleventWebsites = (dashboardItems: DashboardItems) => {
	return (dashboardItems.relevant_websites || []).map((web) => {



		const preference = dashboardItems?.relevant_websites_preferences?.find((pref: RelevantWebsitePreference) => pref.id === web.id)

		return { ...web, hidden: preference?.hidden || false, display_order: preference?.display_order || 0 }
	})
}

const modifiedApps = (dashboardItems: DashboardItems) => {
	return (dashboardItems.used_applications || []).map((app) => {

		const preference = dashboardItems?.used_applications_preferences?.find((pref: UsedApplicationPreference) => pref.client_id === app.client_id)

		return {
			client_id: app.client_id,
			name: app.name,
			description: app.description || '',
			display_order: preference?.display_order || 0,
			dip_app_type: app.dip_app_type,
			meta_data: { app_id: app.app_id, mobile_app_info: app.mobile_app_info, uses_mfa: app.uses_mfa },
			url: app.url,
			hidden: preference?.hidden || false,
			translations: app.translations
		}
	})
};

const getContent = (dashboardItems: DashboardItems, classes: any, editMode: boolean, functions: any, userEmail: string) => {

	return <Grid item xs={12} className={classes.applicationContainerMaxWidth}>
		<Grid item xs={12} className={classes.itemContainer}>
			{(dashboardItems.used_applications_loaded || dashboardItems.recommended_applications_loaded || dashboardItems.used_applications_loaded) ? (
				<>
					{/* Used applications */}
					{dashboardItems.used_applications_loaded && (
						<DashBoardItems
							areaSubject={'app_dashboard.used_applications'}
							dashBoardItems={{ items: modifiedApps(dashboardItems) }}
							getDashBoardItemIcon={getDashboardItemIcon}
							shouldItemBeShownAsActive={shouldItemBeShownAsActive}
							functions={functions}
							handleBannerClick={handleBannerClick}
							itemType="used"
							editMode={editMode}
							userEmail={userEmail}
						/>
					)}
					{/* Recommended applications */}
					{dashboardItems.recommended_applications_loaded && (<DashBoardItems
						areaSubject={'app_dashboard.recommended_applications'}
						dashBoardItems={{ items: getFilteredRecommendedApps(dashboardItems) }}
						getDashBoardItemIcon={getDashboardItemIcon}
						shouldItemBeShownAsActive={shouldItemBeShownAsActive}
						functions={functions}
						handleBannerClick={handleBannerClick}
						itemType="recommended"
						editMode={editMode}
						userEmail={userEmail}
					/>)}
					{/* Relevant websites */}
					{dashboardItems.relevant_websites_loaded && (<DashBoardItems
						areaSubject={'app_dashboard.relevant_websites'}
						dashBoardItems={{ items: getReleventWebsites(dashboardItems) }}
						getDashBoardItemIcon={getDashboardItemIcon}
						shouldItemBeShownAsActive={shouldItemBeShownAsActive}
						functions={functions}
						handleBannerClick={handleBannerClick}
						itemType="relevant"
						editMode={editMode}
						userEmail={userEmail}
					/>)}
				</>
			) : (
				<Grid item xs={12} className={classes.DashboardItemSpinner}>
					<Spinner visible={true} />
				</Grid>
			)}
		</Grid>
	</Grid>
}

const getTranslationKey = (used_applications: UsedApplicationWithPreference[], recommended_applications: RecommendedApplicationWithPreference[], relevant_websites: RelevantWebsiteWithPreference[]) => {
	let translationKey = ''

	if (used_applications.length > 0) {
		if (relevant_websites.length > 0 && recommended_applications.length > 0) {
			translationKey = 'app_dashboard.used_and_recommended_and_public_apps'
		} else if (relevant_websites.length > 0) {
			translationKey = 'app_dashboard.used_and_public_apps'
		} else if (recommended_applications.length > 0) {
			translationKey = 'app_dashboard.used_and_recommended_apps'
		} else {
			translationKey = 'app_dashboard.only_used_apps'
		}
	}

	if (translationKey === '' && relevant_websites.length > 0) {
		if (recommended_applications.length > 0) {
			translationKey = 'app_dashboard.recommended_and_public_apps'
		} else {
			translationKey = 'app_dashboard.only_public_apps'
		}
	}

	if (translationKey === '' && recommended_applications.length > 0) {
		translationKey = 'app_dashboard.only_recommended_apps'
	}

	if (translationKey === '') {
		translationKey = 'app_dashboard.no_applications'
	}

	return translationKey;
}

const getDashboardDescription = (welcomeEndText: string, t: Function, dashboardItems: DashboardItems, classes: any, profile: Profile) => {

	const used_applications = (dashboardItems.used_applications || []).filter((app: UsedApplicationWithPreference) => !app.hidden && app.url);
	const recommended_applications = (dashboardItems.recommended_applications || []).filter((app: RecommendedApplicationWithPreference) => !app.hidden && app.url);
	const relevant_websites = (dashboardItems.relevant_websites || []).filter((app: RelevantWebsiteWithPreference) => !app.hidden);

	let translationKey = getTranslationKey(used_applications, recommended_applications, relevant_websites);

	return (dashboardItems.recommended_applications_loaded && dashboardItems.relevant_websites_loaded.loaded && dashboardItems.used_applications_loaded) ?
		(<Grid item xs={12} className={classes.welcomeText}>
			{`${t('app_dashboard.welcome', { property_name: profile?.first_name })}, ${t(translationKey)}`}
			<span className={classes.descriptionSpace}>{t(welcomeEndText)}</span>
		</Grid>)
		: (<Grid item xs={12} className={classes.welcomeTextSpinner}>
			<Spinner visible={true} />
		</Grid>
		)


}

const getHeader = () => {
	return isMobileDevice() ? 'app_dashboard.inactive_application_overlay_mobile.header' : 'app_dashboard.inactive_application_overlay.header';
}

const getBody = () => {
	return isMobileDevice() ? 'app_dashboard.inactive_application_overlay_mobile.content' : 'app_dashboard.inactive_application_overlay.content'
}

const getWelcomeText = (applications: UsedApplicationWithPreference[], relevantWebsites: RelevantWebsiteWithPreference[], recommendedApplications: RecommendedApplicationWithPreference[]) => {
	return (applications.filter((app: UsedApplicationWithPreference) => !app.hidden && app.url).length > 0 ||
		relevantWebsites.filter((app: RelevantWebsiteWithPreference) => !app.hidden).length > 0 ||
		recommendedApplications.filter((app: RecommendedApplicationWithPreference) => !app.hidden && app.url).length > 0) ? 'app_dashboard.welcome_end' : ''
}

const getClientPopupContent = (showClientAppPopup: boolean, classes: any, toggleClientAppPopup: Function, t: Function) => {
	return showClientAppPopup ? (
		<Grid container className={classes.popupContainer}>
			<ModalPopup paperClassName={classes.popup} overlayClassName={classes.overlay}>
				<Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
					<div onClick={() => toggleClientAppPopup()}>
						<img src={urlJoin(settings.staticFiles.endpoint, '/images/system/Close.svg')} className={classes.close} alt="" />
					</div>
				</Grid>
				<Grid item xs={12} className={classes.popupHeader} >
					{t('app_dashboard.desktop_application_overlay.header')}
				</Grid>
				<Grid item xs={12} >
					<span className={classes.popupContent}>
						{parse(t('app_dashboard.desktop_application_overlay.content'))}
					</span>
				</Grid>
			</ModalPopup>
		</Grid>
	) : null;
}

const getMobilePopupContent = (showMobileAppPopup: boolean, classes: any, toggleMobileAppPopup: Function, PopUpApplication: Application) => {
	return showMobileAppPopup ? (
		<Grid container className={`${classes.popupContainer} ${classes.popupContainerMobile}`} id="imagePopupContainer">
			<ModalPopup paperClassName={classes.popup} overlayClassName={classes.overlay}>
				<Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
					<div onClick={() => toggleMobileAppPopup(PopUpApplication)}>
						<img src={urlJoin(settings.staticFiles.endpoint, '/images/system/Close.svg')} className={classes.close} alt="" />
					</div>
				</Grid>
				<Grid item xs={12} className={classes.nativeAppContainer} >
					<RedirectToNativeApp
						appInfo={PopUpApplication}
						inline={true}
						close={(application: Application) => toggleMobileAppPopup(application)}
						showImagePopup={() => showImagePopup(classes)}
					/>
				</Grid>
			</ModalPopup>
		</Grid>
	) : null;
}

const getInactivePopupContent = (showInactiveAppPopup: boolean, toggleInactiveAppPopup: Function, t: Function, inactivePopupHeader: string, inactivePopupBody: string) => {
	return showInactiveAppPopup ? (
		<DashboardItemPopup
			headerText={t(inactivePopupHeader)}
			bodyText={parse(t(inactivePopupBody))}
			togglePopup={toggleInactiveAppPopup}
		/>
	) : null;
}

const showImagePopup = (classes: any) => {
	var popupContainer = document.getElementById("imagePopupContainer");
	popupContainer?.classList.add(classes.popupContainerShow);
}

const shouldItemBeShownAsActive = (application: Application) => {

	if (isMobileDevice()) {
		return application.dip_app_type !== 'client_app';
	}
	else {
		return application.dip_app_type !== 'mobile_app';
	}
}

const geteditPageDescription = (t: Function) => {
	return (
		<ul>
			<li>{t('app_dashboard.edit_mode_description1')}</li>
			<li>{t('app_dashboard.edit_mode_description2')}</li>
			<li>{t('app_dashboard.edit_mode_description3')}</li>
			<li>{t('app_dashboard.edit_mode_description4')}</li>
			<li>{t('app_dashboard.edit_mode_description5')}</li>
		</ul>
	)
}

const getpageTopContent = (editMode: boolean, classes: any, profile: Profile | undefined, welcomeEndText: string, t: Function, dashboardItems: DashboardItems) => {

	return editMode && !isMobileDevice() ? (
		<Grid item className={`${classes.innerContentMargin} ${classes.dashboardText}`}>
			<Typography className={classes.header}>
				{t('app_dashboard.edit_mode_header')}
			</Typography>
			<Grid item xs={12} className={classes.welcomeText}>
				{geteditPageDescription(t)}
			</Grid>
		</Grid>
	) : (
		<Grid item className={`${classes.innerContentMargin} ${classes.dashboardText}`}>
			<Typography className={classes.header}>
				{t('app_dashboard.header')}
			</Typography>
			{getDashboardDescription(welcomeEndText, t, dashboardItems, classes, profile || {})}
		</Grid>
	)
}

const Dashboard: React.FC<DashboardProps> = ({ profile, dashboardItems, handleInformationIconClick, handleMoveDashboardItem, handleDashboardItemDisplay, handleUpdateUserPreferences, handleResetUserPreferences, editMode, setEditMode }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const [showClientAppPopup, setShowClientAppPopup] = React.useState(false);
	const [PopUpApplication, setPopUpApplication] = React.useState({} as Application);
	const [showMobileAppPopup, setShowMobileAppPopup] = React.useState(false);
	const [showInactiveAppPopup, setShowInactiveAppPopup] = React.useState(false);
	const [inactivePopupHeader, setInactivePopupHeader] = React.useState('');
	const [inactivePopupBody, setInactivePopupBody] = React.useState('');
	const [welcomeEndText, setWelcomeEndText] = React.useState('');
	const buttonText = editMode ? t('app_dashboard.hide_editmode') : t('app_dashboard.show_editmode');

	useEffect(() => {
		setInactivePopupHeader(getHeader());
		setInactivePopupBody(getBody());
		setWelcomeEndText(getWelcomeText(dashboardItems?.used_applications || [], dashboardItems?.relevant_websites || [], dashboardItems?.recommended_applications || []));
	}, [dashboardItems.used_applications, dashboardItems.relevant_websites, dashboardItems.recommended_applications]);

	const toggleClientAppPopup = () => {
		setShowClientAppPopup(!showClientAppPopup);
	}

	const toggleInactiveAppPopup = () => {
		setShowInactiveAppPopup(!showInactiveAppPopup);
	}

	const toggleMobileAppPopup = (app: RecommendedApplicationWithPreference) => {
		const popupApp = {...app, name: app.name || '', app_id: app.meta_data?.app_id || '', mobile_app_info: app.meta_data?.mobile_app_info || [], uses_mfa: app.meta_data?.uses_mfa || false, app_type: '', allowed_callback: ''};
		setPopUpApplication(popupApp);
		setShowMobileAppPopup(!showMobileAppPopup);
	}

	const toggleEditMode = () => {

		if (editMode) {
			handleUpdateUserPreferences();
		}

		setEditMode(!editMode);
	}

	const resetUserPreferences = () => {
		handleResetUserPreferences(setEditMode);
		setEditMode(!editMode);
	}

	const showCustomizationButton = () => {
		
		if ((dashboardItems.relevant_websites_loaded.loaded && !dashboardItems.relevant_websites_loaded.hasError) && (dashboardItems.recommended_applications_loaded.loaded && !dashboardItems.recommended_applications_loaded.hasError) && (dashboardItems.used_applications_loaded.loaded && !dashboardItems.used_applications_loaded.hasError)) {
			return true;
		}

		return false;
	}


	if (showClientAppPopup || showMobileAppPopup || showInactiveAppPopup) {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	return (
		<>
			<Grid container className={`${classes.root}`}>
				{getClientPopupContent(showClientAppPopup, classes, toggleClientAppPopup, t)}
				{getMobilePopupContent(showMobileAppPopup, classes, toggleMobileAppPopup, PopUpApplication)}
				{getInactivePopupContent(showInactiveAppPopup, toggleInactiveAppPopup, t, inactivePopupHeader, inactivePopupBody)}
				<Grid container className={classes.applicationTextContainer}>
					<Grid container className={classes.applicationTextSubContainer}>
						{getpageTopContent(editMode, classes, profile, welcomeEndText, t, dashboardItems)}
						{showCustomizationButton() && (
							<Grid item className={`${classes.editItemContainer} ${classes.editButtonContainerBigScreen}`}>
								<div className={classes.editItemSubContainer}>
									<Grid className={classes.editButtonContainerShrink}>
										<Grid className={classes.editButtonContainerShrink}>
											<Grid item xs={12} className={classes.editButtonContainer}>
												<Button
													type="button"
													variant="contained"
													color="primary"
													className={classes.button}
													onClick={toggleEditMode}
													id="saveButton"
												>
													{buttonText}
												</Button>
											</Grid>
											{editMode && (<Grid item xs={12} onClick={() => resetUserPreferences()} className={classes.linkText}>
												{t('app_dashboard.restore_to_defaults')}
											</Grid>)
											}
										</Grid>
									</Grid>
								</div>
							</Grid>
						)}
					</Grid>
				</Grid>
				<Grid container className={classes.applicationContainer}>
					<Grid container className={classes.innerContentMargin}>
						{getContent(dashboardItems, classes, editMode, { toggleInactiveAppPopup: toggleInactiveAppPopup, toggleClientAppPopup: toggleClientAppPopup, toggleMobileAppPopup: toggleMobileAppPopup, handleInformationIconClick: handleInformationIconClick, toggleEditMode, handleDashboardItemDisplay, handleMoveDashboardItem }, profile?.email || "")}
					</Grid>
				</Grid>
				{(dashboardItems.used_applications_loaded && dashboardItems.recommended_applications_loaded && dashboardItems.used_applications_loaded) && (
					<Grid item className={`${classes.editItemContainer} ${classes.editButtonContainerSmallScreen}`}>
						<Grid className={classes.editItemSubContainer}>
							<Grid item xs={12} className={classes.editButtonContainer}>
								<Button
									type="button"
									variant="contained"
									color="primary"
									className={classes.button}
									onClick={toggleEditMode}
								>
									{buttonText}
								</Button>
							</Grid>
							{editMode && (<Grid item xs={12} onClick={() => resetUserPreferences()} className={classes.linkText}>
								{t('app_dashboard.restore_to_defaults')}
							</Grid>)
							}
						</Grid>
						{editMode && (
							<>
								<Grid className={classes.editPageMobileHeaderContainer}>
									<Typography className={classes.mobileHeaderEdit}>
										{t('app_dashboard.edit_mode_header_mobile')}
									</Typography>
								</Grid>
								<Grid className={`${classes.editPageMobiledescriptionContainer} ${classes.welcomeText}`}>
									{geteditPageDescription(t)}
								</Grid>
							</>
						)}
					</Grid>
				)}
			</Grid>
		</>
	);
};

export default Dashboard;
