import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Cultures } from '../../store/cultures/types';
import { Menu, MenuItem, Modal } from '@material-ui/core';
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';
import { Profile } from '../../store/profile/types';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../Providers/AuthenticationProvider';
import { clearProfile as actionClearProfile } from "../../store/profile/actions";
import { connect } from 'react-redux';
import { AppState } from "../../store";
import { Transition } from 'react-transition-group';

// Graphics
import { useStyles } from './Styles';

interface UserMenuSelectorProps {
    profile?: Profile,
    cultures: Cultures,
    clearProfile: Function
}

function isVisible(e: HTMLSpanElement) {
    return !!( e.offsetWidth || e.offsetHeight || e.getClientRects().length );
}

const setMenuStyles = () => {
    const menu = document.getElementById("DIPMenuItems")?.children[2] as HTMLDivElement;
    
    if (menu && isVisible(menu)) {
        const header = document.getElementById("DIPHeader");
        const marginTop = header && (header?.offsetHeight - document.documentElement.scrollTop) ;

        const myModal = document.getElementById("DIPUserModal")?.firstChild as HTMLDivElement || null;
        const topValue = `${marginTop}px`;
        myModal && myModal.style.setProperty("inset", `${topValue} 0px 0px`, 'important');
    }
}

const getMenuContent = (classes:any, anchorEl: HTMLSpanElement | null, handleClose: Function, logoutClick: Function, profile: Profile, t: Function) => {
    return (<Modal
                className={`${classes.modal} triggerWarning`}
                open={Boolean(anchorEl)}
                id="DIPUserModal"
            >
                <Transition timeout={300} onEnter={setMenuStyles}>
                    <Menu
                        classes={{ paper: classes.paper }}
                        anchorEl={anchorEl}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        open={Boolean(anchorEl)}
                        marginThreshold={11}
                        onClose={() => handleClose()}
                        onClick={() => handleClose()}
                        MenuListProps={{ "translate": "no" }}
                        autoFocus={false}
                        id="DIPMenuItems"
                    >
                        <MenuItem
                            className={classes.menuItem}
                        >
                            <Link to="/your-profile" className={classes.menuItemLink}>
                                {t('header.your-profile')}
                            
                                <span>
                                    <div className={classes.menuItemName}>
                                        {profile.name}
                                    </div>
                                    <div className={classes.menuItemEmail}>
                                        {profile.email}
                                    </div>
                                </span>
                            </Link>
                        </MenuItem>
                        <MenuItem
                            className={classes.menuItem}
                        >
                            <Link to={window.location.pathname.indexOf('/edit-profile') > -1 ? `/edit-profile/${Math.random()}` : '/edit-profile/'} className={classes.menuItemLink} >
                                {t('header.edit-profile')}
                            </Link>
                        </MenuItem>
                        <MenuItem
                            className={classes.menuItem}
                        >
                            <Link to="/change-password" className={classes.menuItemLink}>
                                {t('header.change-password')}
                            </Link>
                        </MenuItem>
                        <MenuItem
                            className={`${classes.menuItem} ${classes.menuItemLast}`}
                            onClick={() => logoutClick()}
                        >
                            <span className={classes.menuItemLink}>{t('header.logout')}</span>
                        </MenuItem>
                    </Menu>
                </Transition>
            </Modal>
            )
}

const getUserInitials = (profile: Profile | undefined, setInitials: Function) => {

    const userInitials = (profile && ((profile.first_name?.substring(0, 1) || '') + (profile.last_name?.substring(0, 1) || ''))) || '';
    
    setInitials(userInitials.toUpperCase());        
}

const UserMenuSelector: React.FC<UserMenuSelectorProps> = ({ profile, clearProfile, cultures }) => {
    const { isAuthenticated, loginWithRedirect, logoutWithRedirect } = useAuthentication();
    const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);
    const [initials, setInitials] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(true);
    const classes = useStyles();
    const { t } = useTranslation();
    
    useEffect(() => {
        getUserInitials(profile, setInitials);
        setIsLoading(false);

        window.addEventListener('resize', setMenuStyles, true);

        return () => {
			// When unmounting make sure to remove the eventListener again
            document.removeEventListener('resize', setMenuStyles, true);
        }

    }, [profile]);
  

    function handleClick() {
        if (anchorEl === null) {
            var elements = document.getElementsByClassName("DIPUserMenu");

            Array.from(elements).forEach((element) => {
                if (isVisible(element as HTMLSpanElement)) {
                    setAnchorEl(element as HTMLSpanElement);
                } 
            });

        } else {
            setAnchorEl(null);
        }
    }

    function handleClose() {
        setAnchorEl(null);
    }

    

    

    const logoutClick = () => {
        logoutWithRedirect();
        clearProfile();
    };

    const getUserIconAndText = () => {
    
        return !isAuthenticated ? (
            <span className={`${classes.languageContainer} triggerWarning`} onClick={loginClick}>
                <img src={urlJoin(settings.staticFiles.endpoint, '/images/system/Header_User_Unknown.svg')} className={`${classes.menuIcon} triggerWarning`} alt="" />{t('header.login')}
            </span>
        ) : (
                <>
                    <span className={`${classes.languageContainer} ${classes.cultureNameBigScreen} triggerWarning DIPUserMenu`} onClick={handleClick}>
                        <img src={urlJoin(settings.staticFiles.endpoint, '/images/system/Header_User.svg')} className={`${classes.menuIcon} triggerWarning`} alt="" />{initials}
                    </span>
                    <span className={`${classes.languageContainer} ${classes.cultureNameSmallScreen} triggerWarning DIPUserMenu`} onClick={handleClick}>
                        <img src={urlJoin(settings.staticFiles.endpoint, '/images/system/Header_User.svg')} className={`${classes.menuIcon} triggerWarning`} alt="" />{initials}
                    </span>
                </>
            )
        };

    const loginClick = () => {
        loginWithRedirect({ ui_locales: (cultures && cultures.currentCulture) || 'en', appState: {redirectUri: '/'} });
    };

    return isLoading ? null : (
        <React.Fragment>
            {getUserIconAndText()}
            {profile && getMenuContent(classes, anchorEl, handleClose, logoutClick, profile, t)}
        </React.Fragment>
    );
};

const mapDispatchToProps = () => ({
    clearProfile: actionClearProfile
});

const mapStateToProps = (state: AppState) => ({
    profile: state.profile,
    cultures: state.cultures
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMenuSelector);
