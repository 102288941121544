import deepmerge from 'deepmerge';
import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { MaterialTheme } from '@danfoss/webex-ui/dist/mui';
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';


const themeDIP = {
  overrides: {
    MuiContainer: {
      root: {
        "&.myAccount": {
          display: 'flex',
          flexGrow: '1',
          overflow: 'hidden',
          padding: 0,
          maxWidth: '1440px'
        },
        "&.myAccountFullWidth": {
          display: 'flex',
          flexGrow: '1',
          overflow: 'hidden',
          padding: 0,
          maxWidth: '100%'
        }
      }
    },
    MuiTab: {
      root: {
        [MaterialTheme.breakpoints.up('sm')]: {
          backgroundSize: '33px'
        },
        [MaterialTheme.breakpoints.only('xs')]: {
          backgroundSize: '25px',
        },
        "&.myAccount": {
          [MaterialTheme.breakpoints.between('xs', 'sm')]: {
            marginRight: '4px',
          },
          marginTop: '4px',
          [MaterialTheme.breakpoints.up('sm')]: {
            minWidth: '88px',
            borderRadius: '4px'
          },
          [MaterialTheme.breakpoints.only('xs')]: {
            minWidth: '67px'
          },
          width: '88px',
          padding: '17px 7px 11px 5px',
          left: '2px',
          textTransform: 'none'
        },
        "&:hover": {
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 'center',
          [MaterialTheme.breakpoints.up('sm')]: {
            backgroundPositionY: '17px'
          },
          [MaterialTheme.breakpoints.only('xs')]: {
            backgroundPositionY: '12px'
          },
          color: colors.webexuiDarkGrayish,
          "& > span": {
            color: colors.webexuiDarkGrayish,
            marginTop: '38px'
          },
          "&.home": {
            backgroundImage: `url(${urlJoin(settings.staticFiles.endpoint,'/images/system/Home_active.svg')})`
          },
          "&.profile": {
            backgroundImage: `url(${urlJoin(settings.staticFiles.endpoint,'/images/system/Profile_active.svg')})`
          },
          "&.security": {
            backgroundImage: `url(${urlJoin(settings.staticFiles.endpoint,'/images/system/Security_active.svg')})`,
            minHeight: '108px'
          },
          "&.apps": {
            backgroundImage: `url(${urlJoin(settings.staticFiles.endpoint,'/images/system/Apps_active.svg')})`
          }
        },
        "&.active": {
            backgroundColor: colors.webexuiWhite,
            boxShadow: '-1px 0 4px 0 rgba(0,0,0,0.25)',
            backgroundPositionX: 'center',
            [MaterialTheme.breakpoints.up('sm')]: {
              backgroundPositionY: '17px'
            },
            [MaterialTheme.breakpoints.only('xs')]: {
              backgroundPositionY: '12px'
            },
            backgroundRepeat: 'no-repeat',
            "& > span": {
              color: colors.webexuiDarkGrayish,
              marginTop: '38px'
            },
            "&.home": {
              backgroundImage: `url(${urlJoin(settings.staticFiles.endpoint,'/images/system/Home_active.svg')})`
            },
            "&.profile": {
              backgroundImage: `url(${urlJoin(settings.staticFiles.endpoint,'/images/system/Profile_active.svg')})`
            },
            "&.security": {
              backgroundImage: `url(${urlJoin(settings.staticFiles.endpoint,'/images/system/Security_active.svg')})`,
              minHeight: '108px'
            },
            "&.apps": {
              backgroundImage: `url(${urlJoin(settings.staticFiles.endpoint,'/images/system/Apps_active.svg')})`
            }
        },
        "&.inactive": {
          backgroundColor: colors.webexuiGray,
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 'center',
          [MaterialTheme.breakpoints.up('sm')]: {
            backgroundPositionY: '17px'
          },
          [MaterialTheme.breakpoints.only('xs')]: {
            backgroundPositionY: '12px'
          },
          "& > span": {
            color: colors.webexuiGrayText,
            marginTop: '38px'
          },
          "&.home": {
            backgroundImage: `url(${urlJoin(settings.staticFiles.endpoint,'/images/system/Home_inactive.svg')})`
          },
          "&.profile": {
            backgroundImage: `url(${urlJoin(settings.staticFiles.endpoint,'/images/system/Profile_inactive.svg')})`
          },
          "&.security": {
            backgroundImage: `url(${urlJoin(settings.staticFiles.endpoint,'/images/system/Security_inactive.svg')})`,
            minHeight: '108px'
          },
          "&.apps": {
            backgroundImage: `url(${urlJoin(settings.staticFiles.endpoint,'/images/system/Apps_inactive.svg')})`
          }
        }
      },
      textColorSecondary: {
        "&.Mui-selected": {
          color: colors.webexuiDarkGrayish
        }
      },
      wrapper: {
        [MaterialTheme.breakpoints.between('xs', 'sm')]: {
          width: '80%',
          wordBreak: 'break-all'
        },
        display: 'block'
      }
    },
    MuiTabs: {
      scroller: {
        [MaterialTheme.breakpoints.only('xs')]: {
          height: '54px'
        },
        [MaterialTheme.breakpoints.only('sm')]: {
          height: '77px'
        }
      }
    },
    PrivateTabIndicator: {
      colorSecondary: {
        display: 'none'
      }
    },
    MuiGrid: {
      root: {
        "&.myAccount": {
          [MaterialTheme.breakpoints.between('xs', 'sm')]: {
            maxWidth: '100% !important'
          },
          [MaterialTheme.breakpoints.up('md')]: {
            maxWidth: '90px'
          },
          backgroundColor: colors.webexuiGraySoft,
          [MaterialTheme.breakpoints.up('lg')]: {
            marginTop: '30px'
          }
        },
        "&.myAccountContent": {
          backgroundColor: colors.webexuiWhite,
          margin: 0,
          //boxShadow: '-1px 0 4px 0 rgba(0,0,0,0.25)',
          height: '100%'
        },
        "&.myAccountContentEditMode": {
          backgroundColor: colors.webexuiWhite,
          margin: 0,
          //boxShadow: '-1px 0 4px 0 rgba(0,0,0,0.25)',
          height: '100%'
        }
      },
      item: {
        "&.myAccount": {
          padding: '0px !important'
        },
        "&.center": {
          textAlign: 'center'
        },
        "&.myAccountContent": {
          [MaterialTheme.breakpoints.only('xs')]: {
            padding: '12px 16px'
          },
          [MaterialTheme.breakpoints.up('sm')]: {
            padding: '47px 0 0 0'
          }
        }
      }
    },
    MuiButton: {
      root: {
        fontWeight: 600,
        fontSize: '16px'
      },
      outlined: {
        padding: '6px 16px 7px',
        border: `1px solid ${colors.webexuiGray}`,
        backgroundColor: colors.webexuiWhite,
        "&:hover": {
          border: '1px solid #ADADAD',
          backgroundColor: colors.webexuiWhite
        }
      },
      contained: {
        padding: '7px 16px',
        '&$focusVisible': {
          boxShadow: 'none',
        }
      },
      focusVisible: {
        boxShadow: 'none'
      }
    },
    MuiButtonBase: {
      root: {
        fontWeight: 600,
        fontSize: '16px'
      }
    },
    MuiTooltip: {
      tooltip: {
        "&.MuiTooltip-tooltip": {
          backgroundColor: colors.webexuiWhite,
          color: colors.webexuiGrayText,
          border: `1px solid ${colors.webexuiGray}`,
          fontFamily: 'myriad-pro, Myriad Pro',
          fontSize: '16px',
          fontWeight: 'normal',
          lineHeight: '24px',
          padding: '15px',
          boxShadow: '0 1px 4px 0 rgba(0,0,0,0.5)',
          borderRadius: '2px',
          minWidth: '322px'
        }
      },
      popper: {
        top: '16px !important'
      }
    },
    MuiInputAdornment: {
      root: {
        display: 'inline-table'
      },
      positionEnd: {
        backgroundColor: colors.webexuiGrayBg,
        marginRight: '5px'
      }
    },
    MuiToolbar: {
      root: {
        [MaterialTheme.breakpoints.down('xs')]: {
          paddingRight: '0px'
        },
        marginRight: '10px'
      },
      regular: {
        minHeight: '64px',
        [MaterialTheme.breakpoints.down('md')]: {
          minHeight: '53px', 
        },
        [MaterialTheme.breakpoints.down('sm')]: {
          minHeight: '42px', 
        },
      }
    },
    MuiAppBar: {
      root: {
        zIndex: 1300
      }
    },
    MuiInputBase: {
      root: {
        [MaterialTheme.breakpoints.only('xs')]: {
          margin: '5px 0px'
        },
        borderRadius: '2px !important',
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          border: 0
        },
        "& div.MuiSelect-select": {
          fontSize: '15px !important',
          fontFamily: 'myriad-pro, Myriad Pro',
          fontWeight: '400',
          lineHeight: '24px',
        },
        "& input.Mui-disabled": {
          color: "#aaaaaa !important"
        }
      }
    },
    MuiSelect: {
      root: {
        width: '100%',
        [MaterialTheme.breakpoints.only('xs')]: {
          margin: '5px 0px'
        },
        position: 'relative'
      },
      select: {
        padding: '5px 15px',
        paddingRight: '17px',
        color: colors.webexuiBlack
      }
    },
    MuiInput: {
      root: {
        "& select.Mui-disabled": {
          color: colors.webexuiGrayText
        }
      },
      underline: {
        "&.select": {
          padding: '5px 0px'
        }
      }
    },
    MuiAccordion: {
      root: {
        '&::before': {
          backgroundColor: colors.webexuiWhite,
          top: '0px'
        },
        '&$expanded': {
          margin: 0
        }
      }
    },
    MuiAccordionSummary: {
      root: {
        padding: 0,
        '&$expanded': {
          margin: 0,
          minHeight: '54px'
        },
        borderBottom: `1px solid ${colors.webexuiGraySoft}`
      },
      expandIcon: {
        transition: 'none',
        margin: '0 -4px 13px',
        '&$expanded': {
          margin: '11px 5px 0 0'
        }
      },
      content: {
        '&$expanded': {
          margin: '12px 0'
        }
      },
      '&$expanded': {
        margin: 0
      }
    },
    MuiAccordionDetails: {
      root: {
        padding: 0
      }
    },
    MuiIconButton: {
      edgeEnd: {
        marginRight: '2px'
      }
    },
    MuiMenuItem: {
      root: {
        "&:hover": {
          backgroundColor: colors.webexuiWhite
        }
      }
    }
  }
};

export default deepmerge(themeDIP, MaterialTheme);