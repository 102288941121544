import forge from 'node-forge'
import { isAndroid, isIOS } from 'mobile-device-detect';
import { encryptData, EncryptedDataResponse } from '../api/system';

export const isLocalhost = (): boolean => {
  return Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    (window.location.hostname && window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    ))
  )
};

export const isContentOnly = (): boolean => {
  return (window.location.pathname || '').indexOf("content-only") > -1
};

export const getRegistrationCenterApiKey = (hashKey:string) => {
  const hmac = forge.hmac.create();
  hmac.start('sha256', hashKey);
  hmac.update(getUtcDate(Date.now()));
  return hmac.digest().toHex()
}

export const getUtcDate = (date: Date | number) : string => {
  return (typeof(date) === 'number' && new Date(date) || date as Date).toISOString().substring(0, 10).replace(/-/g, "")
}

export const isMobileDevice = () => {
	return isAndroid || isIOS;
}

export const getEncryptedData = async (dataToEncrypt: string, accessToken: string) => {
  return await encryptData(dataToEncrypt, accessToken).then((response: EncryptedDataResponse) => {
    return response?.encrypted_data[0]?.encrypted_value || '';
  })
}